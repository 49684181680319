// Get the logos
import columbiaLogo from '../../ui/brandlogos/columbia_logo.png';
import sorelLogo from '../../ui/brandlogos/sorel_logo.png';
import mhwLogo from '../../ui/brandlogos/mhw_logo.png';
import pranaLogo from '../../ui/brandlogos/prana_logo.png';

export default function BrandLockup({text, className}) {
    const cls = (...classes) => classes.filter(Boolean).join(' ');

    return(
        <div className={cls('brand-lockup', text && `${className}` )}>
            <div className='brand-lockup__logo stack'>
                <img id='brand-lockup__columbia' src={columbiaLogo} alt='Columbia Sportswear logo' />
                { text ? <span className='brand-lockup__text'>Portland, OR</span>  : null }
            </div>
            <div className='brand-lockup__logo stack'>
                <img id='brand-lockup__sorel' src={sorelLogo} alt='SOREL logo' />
                { text ? <span className='brand-lockup__text'>Portland, OR</span>  : null }
            </div>
            <div className='brand-lockup__logo stack'>
                <img id='brand-lockup__mhw' src={mhwLogo} alt='Mountain Hardwear logo' />
                { text ? <span className='brand-lockup__text'>Richmond, CA</span>  : null }
            </div>
            <div className='brand-lockup__logo stack'>
                <img id='brand-lockup__prana' src={pranaLogo} alt='prAna logo' />
                { text ? <span className='brand-lockup__text'>Carlsbad, CA</span>  : null }
            </div>
        </div>
    );
}