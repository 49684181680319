import React from 'react';
import { Container, Heading, TabList, Tabs, TabPanels, Tab, TabPanel, Fade, SimpleGrid } from '@chakra-ui/react';
import HomeLayout from '../../../components/layouts/homelayout';
import Seo from '../../../components/sections/seo';

// Components
import CarouselSimple from '../../../components/sections/carouselsimple';
import BrandLockup from '../../../components/sections/brandlockup';

// Hero Images
import naHeroImg from '../../../components/ui/regionalcareers/northamerica/hq_entry_16_9.jpg';
import retailHeroImg from '../../../components/ui/regionalcareers/northamerica/retail_hero_16_9.jpg';
import dcHeroImg from '../../../components/ui/regionalcareers/northamerica/dc_hero_16_9.jpg';

// Images
import naPartioImg from '../../../components/ui/globalcareers/regions/northamerica/partio_top.jpg';
import naLifeImg from '../../../components/ui/globalcareers/regions/northamerica/life_1.jpg';
import naLife2Img from '../../../components/ui/globalcareers/regions/northamerica/life_2.jpg';

// icons
import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined';
import DiscountOutlinedIcon from '@mui/icons-material/DiscountOutlined';
import RouteOutlinedIcon from '@mui/icons-material/RouteOutlined';
import SelfImprovementOutlinedIcon from '@mui/icons-material/SelfImprovementOutlined';
import SavingsOutlinedIcon from '@mui/icons-material/SavingsOutlined';
import PsychologyOutlinedIcon from '@mui/icons-material/PsychologyOutlined';
import VolunteerActivismOutlinedIcon from '@mui/icons-material/VolunteerActivismOutlined';

// Carousel Images
import facilitiesImg from '../../../components/ui/globalcareers/carousel/facilities_administration.jpg';
import hrLegalImg from '../../../components/ui/globalcareers/carousel/hr_legal_finance.jpg';
import innovationImg from '../../../components/ui/globalcareers/carousel/innovation.jpg';
import manufacturingImg from '../../../components/ui/globalcareers/carousel/manufacturing.jpg';
import productDesignImg from '../../../components/ui/globalcareers/carousel/product_design.jpg';
import salesImg from '../../../components/ui/globalcareers/carousel/sales.jpg';
import supplyChainImg from '../../../components/ui/globalcareers/carousel/supply_chain.jpg';
import technologyImg from '../../../components/ui/globalcareers/carousel/technology.jpg';
import retailCaroImg from '../../../components/ui/globalcareers/carousel/retail.jpg';
import maktImg from '../../../components/ui/globalcareers/carousel/marketing.jpg';
import custCareImg from '../../../components/ui/globalcareers/carousel/customer_care.jpg';
import distImg from '../../../components/ui/globalcareers/carousel/distribution.jpg';

export default function NATabPanel() {

    const usjobs = [{
        id: 1,
        heading: 'Technology',
        image: `${technologyImg}`,
        url: 'https://columbiasportswearcompany.wd5.myworkdayjobs.com/CSC_Careers?source_id=csccom&source_p=careers_na'
    },
    {
        id: 2,
        heading: 'Ecommerce & Customer Care',
        image: `${custCareImg}`,
        url: 'https://columbiasportswearcompany.wd5.myworkdayjobs.com/CSC_Careers?source_id=csccom&source_p=careers_na'
    },
    {
        id: 3,
        heading: "Retail",
        image: `${retailCaroImg}`,
        url: 'https://columbiasportswearcompany.wd5.myworkdayjobs.com/CSC_Careers?source_id=csccom&source_p=careers_na'
    },
    {
        id: 4,
        heading: 'Distribution',
        image: `${distImg}`,
        url: 'https://columbiasportswearcompany.wd5.myworkdayjobs.com/CSC_Careers?source_id=csccom&source_p=careers_na'
    },
    {
        id: 5,
        heading: 'Marketing',
        image: `${maktImg}`,
        url: 'https://columbiasportswearcompany.wd5.myworkdayjobs.com/CSC_Careers?source_id=csccom&source_p=careers_na'
    },
    {
        id: 6,
        heading: 'Supply Chain',
        image: `${supplyChainImg}`,
        url: 'https://columbiasportswearcompany.wd5.myworkdayjobs.com/CSC_Careers?source_id=csccom&source_p=careers_na'
    },
    {
        id: 7,
        heading: 'Facilities & Administration',
        image: `${facilitiesImg}`,
        url: 'https://columbiasportswearcompany.wd5.myworkdayjobs.com/CSC_Careers?source_id=csccom&source_p=careers_na'
    },
    {
        id: 8,
        heading: 'HR, Legal, & Finance',
        image: `${hrLegalImg}`,
        url: 'https://columbiasportswearcompany.wd5.myworkdayjobs.com/CSC_Careers?source_id=csccom&source_p=careers_na'
    },
    {
        id: 9,
        heading: 'Innovation',
        image: `${innovationImg}`,
        url: 'https://columbiasportswearcompany.wd5.myworkdayjobs.com/CSC_Careers?source_id=csccom&source_p=careers_na'
    },
    {
        id: 10,
        heading: 'Manufacturing',
        image: `${manufacturingImg}`,
        url: 'https://columbiasportswearcompany.wd5.myworkdayjobs.com/CSC_Careers?source_id=csccom&source_p=careers_na'
    },
    {
        id: 11,
        heading: 'Product Design & Development',
        image: `${productDesignImg}`,
        url: 'https://columbiasportswearcompany.wd5.myworkdayjobs.com/CSC_Careers?source_id=csccom&source_p=careers_na'
    },
    {
        id: 12,
        heading: 'Sales',
        image: `${salesImg}`,
        url: 'https://columbiasportswearcompany.wd5.myworkdayjobs.com/CSC_Careers?source_id=csccom&source_p=careers_na'
    },
    ];

    const cajobs = [{
        id: 1,
        heading: 'Retail',
        image: `${retailCaroImg}`,
        url: 'https://columbiasportswearcompany.wd5.myworkdayjobs.com/CSC_Careers?source_id=csccom&source_p=na&locationCountry=a30a87ed25634629aa6c3958aa2b91ea'
    },
    {
        id: 2,
        heading: 'Distribution',
        image: `${distImg}`,
        url: 'https://columbiasportswearcompany.wd5.myworkdayjobs.com/CSC_Careers?source_id=csccom&source_p=na&locationCountry=a30a87ed25634629aa6c3958aa2b91ea'
      },
      {
        id: 3,
        heading: 'Corporate Functions',
        image: `${maktImg}`,
        url: 'https://columbiasportswearcompany.wd5.myworkdayjobs.com/CSC_Careers?source_id=csccom&source_p=na&locationCountry=a30a87ed25634629aa6c3958aa2b91ea'
    },
    ];

  return (
    <HomeLayout>
        <Seo
            title="Careers in North America"
            description="Find your next job in retail, distribution, or at our corporate headquarters."
            //links={[{"rel": "canonical", "href": "https://www.columbiasportswearcompany.com/careers"}]}
        />

        <Container maxW={{base: 'max', lg: "1440px" }} mt={'var(--space-2xl)'} className='flow' >
            <section class="divided-hero page-banner">
                <div class="dh__feature">
                    <div className='flow'>
                        <h1>North America Region</h1>
                        <p>North America is the birthplace of the Columbia brand, home to all our Brand Headquarters, three distribution centers and over 175 retail store locations across the U.S. and Canada.</p>
                        <p>At Columbia Sportswear Company, our family of brands take pride in offering a diverse range of roles that cater to various passions and pursuits. Whether you’re interested in design, supply chain, technology, sales, corporate functions, or more, we have a job for you to do. Join us and be part of a team that values creativity, innovation and collaboration!</p>
                    </div>
                    <div>
                        <img src={naPartioImg} alt='' />
                    </div>
                </div>
            </section>
        </Container>

        <section className='whq'>
            <div className='hero-main height-100 page-banner'>
                <div className='hero-main__container'>
                <div className='hero-main__inner-container'>
                    <div className='hero-main__inner center-left is-top is-left'>
                    <div className='container'>
                        <div className='hero-main__content m-width-80 text-shadow-dark'>
                            <h2>Brand Headquarters</h2>
                            <p>We design, source, market, and distribute outdoor, active, and everyday lifestyle apparel, footwear, accessories, and equipment under four primary brands. Each brand compliments the other to address the diverse needs of customers.</p>
                        </div>
                        <BrandLockup
                            text={true}
                            className={'with-text'}
                        />
                    </div>
                    </div>
                    <div className='hero-main__video-container'>
                        <img src={naHeroImg} alt='' />
                    </div>
                    <div className='hero-main__overlay'></div>
                </div>
                </div>
            </div>
        </section>

        <section className='na-retail'>
            <div className='hero-main height-100'>
                <div className='hero-main__container'>
                <div className='hero-main__inner-container'>
                    <div className='hero-main__inner center-left is-top is-left'>
                    <div className='container'>
                        <div className='hero-main__content m-width-80 text-shadow-dark'>
                            <h2>Retail</h2>
                            <p>With over 175 retail locations, our Retail teams are essential to our business in representing our company, brands and products to our consumers. The retail teams are responsible for creating a memorable consumer experience, from ensuring product availability to creating engaging visual displays and an efficient check out process.</p>
                        </div>
                    </div>
                    </div>
                    <div className='hero-main__video-container'>
                        <img src={retailHeroImg} alt='' />
                    </div>
                    <div className='hero-main__overlay'></div>
                </div>
                </div>
            </div>
        </section>

        <section className='na-distribution'>
            <div className='hero-main height-100'>
                <div className='hero-main__container'>
                <div className='hero-main__inner-container'>
                    <div className='hero-main__inner center-left is-top is-left'>
                    <div className='container'>
                        <div className='hero-main__content m-width-80 flow text-shadow-dark'>
                            <h2>Distribution</h2>
                            <p>We have three distribution centers spread out across North America in Portland, Oregon, Robards, Kentucky and London, Ontario.</p>
                            <p>Our Distribution Centers utilize state-of-the-art technology and dedicated teams to provide Columbia Sportswear Company a competitive advantage in distribution. These teams are responsible for shipping millions of units of apparel, footwear, and equipment annually within the family of brands.</p>
                        </div>
                    </div>
                    </div>
                    <div className='hero-main__video-container'>
                        <img src={dcHeroImg} alt='' />
                    </div>
                    <div className='hero-main__overlay'></div>
                </div>
                </div>
            </div>
        </section>

        <section>
            <Container maxW={{base: 'max', lg: "1440px" }} mt={'var(--space-2xl)'} className='flow'>
                <Heading
                    className='section-heading'
                    fontSize={'var(--step-3)'}
                    textTransform={'none'}
                    mb={'var(--space-l)'}
                >
                        Choose your destination
                </Heading>
                <Tabs isLazy position={'relative'} variant='soft-rounded'>
                    <TabList>
                        <Tab>United States</Tab>
                        <Tab>Canada</Tab>
                    </TabList>
                    
                    <TabPanels className='na-jobs'>
                        <TabPanel className='us-jobs'>
                            <Fade in={true}>
                                <CarouselSimple cards={usjobs}></CarouselSimple>
                            </Fade>
                            
                        </TabPanel>
                        
                        <TabPanel className='ca-jobs'>
                            <Fade in={true}>
                                <CarouselSimple cards={cajobs}></CarouselSimple>
                            </Fade>
                        </TabPanel>

                    </TabPanels>
                </Tabs>
            </Container>
        </section>

        <section className='benefits-and-perks'>
                <Container maxW={{base: 'max', lg: "1440px" }} mt={'var(--space-2xl)'} className='flow' sx={{'--flow-space': 'var(--space-m)'}}>
                    <h2>Benefits & Perks</h2>

                    <div class="auto-gridx3">
                        <div class="wt-card chip has-icon vertical-layout">

                            <div class="wt-card__content">
                                <div class="bubble-icon dark-blue">
                                    <CalendarMonthOutlinedIcon className='icon-in-bubble' />
                                </div>

                                <div class="wt-card__text">
                                    <h4>Hybrid Schedule for Brand HQs & Corporate Offices</h4>
                                </div>
                            </div>
                        </div>

                        <div class="wt-card chip has-icon vertical-layout">

                            <div class="wt-card__content">
                                <div class="bubble-icon blue-green">
                                    <SavingsOutlinedIcon className='icon-in-bubble' />
                                </div>

                                <div class="wt-card__text">
                                    <h4>401k with Generous Employer Matching</h4>
                                </div>
                            </div>
                        </div>

                        <div class="wt-card chip has-icon vertical-layout">

                        <div class="wt-card__content">
                        <div class="bubble-icon green-gray">
                            <PsychologyOutlinedIcon className='icon-in-bubble' />
                        </div>

                        <div class="wt-card__text">
                        <h4>Mental Health Counseling and Support</h4>
                        </div>
                        </div>
                        </div>

                        <div class="wt-card chip has-icon vertical-layout">

                        <div class="wt-card__content">
                            <div class="bubble-icon dark-green">
                                <RouteOutlinedIcon className='icon-in-bubble' />
                            </div>

                        <div class="wt-card__text">
                        <h4>Learning and Development programs </h4>
                        </div>
                        </div>
                        </div>

                        <div class="wt-card chip has-icon vertical-layout">

                            <div class="wt-card__content">
                                <div class="bubble-icon dark-red">
                                    <VolunteerActivismOutlinedIcon className='icon-in-bubble' />
                                </div>

                                <div class="wt-card__text">
                                    <h4>Paid Volunteer Hours</h4>
                                </div>
                            </div>
                        </div>

                        <div class="wt-card chip has-icon vertical-layout">

                            <div class="wt-card__content">
                                <div class="bubble-icon dark-red">
                                    <VolunteerActivismOutlinedIcon className='icon-in-bubble' />
                                </div>

                                <div class="wt-card__text">
                                    <h4>Donation Matching (for Full-Time Employees)</h4>
                                </div>
                            </div>
                        </div>

                        <div class="wt-card chip has-icon vertical-layout">

                            <div class="wt-card__content">
                                <div class="bubble-icon brown">
                                    <SelfImprovementOutlinedIcon className='icon-in-bubble' />
                                </div>

                                <div class="wt-card__text">
                                    <h4>Wellness Reimbursement (For U.S.-based employees)</h4>
                                </div>
                            </div>
                        </div>

                        <div class="wt-card chip has-icon vertical-layout">

                            <div class="wt-card__content">
                                <div class="bubble-icon blue-green">
                                    <DiscountOutlinedIcon className='icon-in-bubble' />
                                </div>

                                <div class="wt-card__text">
                                    <h4>Retail Discounts on Columbia, SOREL, Mountain Hardwear, and prAna</h4>
                                </div>
                            </div>
                        </div>

                    </div>
                </Container>
            </section>

            <section className='life-in-emea'>
                <Container maxW={{base: 'max', lg: "1440px" }} mt={'var(--space-2xl)'} className='flow' sx={{'--flow-space': 'var(--space-m)'}}>
                    <h2>Life in North America</h2>
                    <p>Joining Columbia Sportswear in North America is joining a team that believes in the power of hard work but also knows that a little fun goes a long way.  We take pride in our culture of people who believe in enjoying an active lifestyle, relentless improvement, and doing the right thing.</p>

                    <SimpleGrid columns={{ base: 1, md: 2 }} spacing={2}>
                        <img src={naLife2Img} alt='A group on the patio' />
                        <img src={naLifeImg} alt='Racers preparing for 5K' />
                    </SimpleGrid>
                </Container>
            </section>


    </HomeLayout>
  );
}
